export default () => {
  return [
    {
      name: 'Basic',
      value: {
        premiumFeatures1: {
          value: false
        },
        premiumFeatures2: {
          value: false
        },
        premiumFeatures3: {
          value: false
        },
        selfMonitoringAndExercisesGraphs: {
          value: true
        }
      }
    },
    {
      name: 'Premium tier 1',
      value: {
        premiumFeatures1: {
          value: true
        },
        premiumFeatures2: {
          value: false
        },
        premiumFeatures3: {
          value: false
        },
        selfMonitoringAndExercisesGraphs: {
          value: true
        }
      }
    },
    {
      name: 'Premium tier 2',
      value: {
        premiumFeatures1: {
          value: true
        },
        premiumFeatures2: {
          value: true
        },
        premiumFeatures3: {
          value: false
        },
        selfMonitoringAndExercisesGraphs: {
          value: true
        }
      }
    },
    {
      name: 'Premium tier 3',
      value: {
        premiumFeatures1: {
          value: true
        },
        premiumFeatures2: {
          value: true
        },
        premiumFeatures3: {
          value: true
        },
        selfMonitoringAndExercisesGraphs: {
          value: true
        }
      }
    },
  ]
}