<template>
  <dashboard-content full-width>
    <v-row>
      <v-col class="col-12 py-0">
        <h1 class="mb-4">
          Edit user
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-row justify="space-between">
          <v-col class="col-auto">
            <v-btn
              :to="{ name: 'users' }"
              color="primary"
            >
              <v-icon class="mr-2">
                {{ mdiChevronLeft }}
              </v-icon> Back
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-card>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-model="user.first_name"
                :rules="[v => !!v || 'First name is required']"
                label="First name"
                autocomplete="given-name"
                required
              />
              <v-text-field
                v-model="user.last_name"
                :rules="[v => !!v || 'Last name is required']"
                label="Last name"
                autocomplete="family-name"
                required
              />
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                label="Email"
                autocomplete="email"
                :disabled="!!$route.params.id"
                required
              />
              <v-select
                v-model="user.role"
                :items="roles"
                :rules="[v => !!v || 'Role is required']"
                item-text="name"
                item-value="value"
                label="Role"
                required
              />
              <v-select
                v-model="user.flags"
                :items="flags"
                item-text="name"
                item-value="value"
                label="Tier"
                required
              />
              <v-row class="mt-4 justify-center">
                <v-col class="col-auto">
                  <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    </v-col>
    </v-row>
  </dashboard-content>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import * as uniqid from 'uniqid'
import DashboardContent from '@/components/dashboard/DashboardContent'
import roles from '@/kit/dictionaries/roles'
import flags from '@/kit/dictionaries/flags'
import firebase from '@/firebase'
import emailValidation from '@/helpers/emailValidation'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'UserEdit',
  components: { DashboardContent },
  data: () => ({
    mdiChevronLeft,
    user: {
      email: null,
      last_name: null,
      first_name: null,
      role: null,
      flags: null,
      password: uniqid()
    },
    valid: true,
    emailRules: [v => !!v || 'Email is required', v => emailValidation(v)],
    roles: roles(),
    flags: flags(),
    isEditPage: false,
    loaded: true
  }),
  computed: mapGetters({
    userState: 'authentication/user'
  }),
  async mounted() {
    this.loaded = false

    await this.$firebase
      .firestore()
      .collection('users')
      .doc(this.$route.params.id)
      .get()
      .then(res => {
        this.user = res.data()
      })

    },
    methods: {
      ...mapMutations({changeFlagValue: 'authentication/changeFlagValue'}),
      async save() {
        this.loaded = false

        this.changeFlagValue({
          name: 'premiumFeatures1',
          set: this.user.flags.premiumFeatures1.value
        })
        this.changeFlagValue({
          name: 'premiumFeatures2',
          set: this.user.flags.premiumFeatures2.value
        })
        this.changeFlagValue({
          name: 'premiumFeatures3',
          set: this.user.flags.premiumFeatures3.value
        })
        
        // Make sure selfMonitoringAndExercisesGraph
        // is consistent with what's in vuex state
        const currentGlobalFlags = this.userState.flags.selfMonitoringAndExercisesGraphs.value
        this.user.flags.selfMonitoringAndExercisesGraphs.value = currentGlobalFlags

        const data = {
          flags: this.user.flags,
          role: this.user.role,
          email: this.user.email,
          last_name: this.user.last_name,
          first_name: this.user.first_name
        }


        await firebase.db
          .collection('users')
          .doc(this.$route.params.id)
          .update(data)
          .then(() => {
            this.$router.push({ name: 'users' })
            this.$notify({ text: 'User was successfully Updated.' })
          })
          .catch(e => (this.error = e))

        this.loaded = true
      }
    }
  }
</script>
