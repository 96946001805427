export default () => {
  return [
    {
      name: 'Provider',
      value: 'provider'
    },
    {
      name: 'Admin',
      value: 'admin'
    }
  ]
}
